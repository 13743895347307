<template>
  <div>
    <VueUeditorWrap class="VueUeditorWrap sos" :config="myConfig" v-model="msg" />
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import aaa from "@/config/config";
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      msg: "",
      myConfig: {
        serverUrl: `${aaa.aps}/ueditor_upload`,
        toolbars: [
          [
            "fullscreen", //全屏
            "source", //查看源代码
            "|",
            "undo", //撤回
            "redo", //还原
            "|",
            "bold", //粗体
            "italic", //斜体
            "underline", //下划线
            "fontborder", //边框线
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "cleardoc",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "link",
            "unlink",
            "anchor",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "simpleupload", //单张图片上传
            "insertimage", //多张图片上传
            "emotion",//表情
            "pagebreak",
            "background",//背景
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "splittocells",
            "splittorows",
            "splittocols",
            "charts",
            "|",
            "print", //打印
            "preview", //浏览
            // "searchreplace",//查询替换
            // "drafts" //草稿箱加载
          ],
        ],
        autotypeset: {
          mergeEmptyline: false, //合并空行
          // removeClass: true,              //去掉冗余的class
          removeEmptyline: false, //去掉空行
          // textAlign:"left",               //段落的排版方式，可以是 left,right,center,justify 去掉这个属性表示不执行排版
          // imageBlockLine: 'center',       //图片的浮动方式，独占一行剧中,左右浮动，默认: center,left,right,none 去掉这个属性表示不执行排版
          // pasteFilter: false,             //根据规则过滤没事粘贴进来的内容
          // clearFontSize: false,           //去掉所有的内嵌字号，使用编辑器默认的字号
          // clearFontFamily: false,         //去掉所有的内嵌字体，使用编辑器默认的字体
          // removeEmptyNode: false,         // 去掉空节点
          //可以去掉的标签
          // removeTagNames: {标签名字:1},
          indent: true, // 行首缩进
          indentValue: "2em", //行首缩进的大小
          bdc2sb: false,
          tobdc: false,
        },
        autoHeightEnabled: false, // 编辑器是否自动被内容撑高
        autoFloatEnabled: false, // 工具栏是否可以浮动
        initialFrameHeight: 500, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器高度
        enableAutoSave: false, // 关闭自动保
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        //serverUrl: "/manage/v1/ueditor/upload?handle=uploadimage",
        // serverUrl: "http://118.24.43.196:9999/api/common/uploadImg",
        // imageAllowFiles:['.png','.jpg','jpeg','git','bmp'],
        // imageAllowName:'file',
        // imageUrlPrefix: "http://118.24.43.196:9999/api/common/uploadImg",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: process.env.BASE_URL + "UE/",
      },
    };
  },
  props: ["sentContent","code"],
  watch: {
    sentContent(val) {
      this.msg = val;
    },
    msg(val) {
      this.$emit("getUeContent", val,this.code);
    },
  },
  created() {
    this.msg = this.sentContent;

    console.log(process.env.BASE_URL)
  },
  mounted() {},
  updated() {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.zIndex /deep/.edui-editor {
  z-index: 100 !important;
}
.sos{
  line-height: 20px !important;
}
</style>
